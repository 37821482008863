<template>
  <v-layout row wrap>
    <v-flex xs12 max-width-1400 full-width ma-auto px-2>
      <v-container grid-list-xl fluid ma-0 pa-0>
        <v-layout row wrap>
          <v-flex xs12>
            <h3
              class="primary--text graphik-medium my-3 text-xs-center"
              :class="{'font-25': $vuetify.breakpoint.smAndUp, 'font-20': $vuetify.breakpoint.xsOnly}"
            >{{ $ml.get('about_products_title') }}</h3>

            <template v-if="$vuetify.breakpoint.smAndUp">
              <v-layout row wrap>
                <v-flex xs12 sm4>
                  <v-img
                    contain
                    class="px-2"
                    :src="images.car"
                    :lazy-src="images.car"
                  ></v-img>
                </v-flex>

                <v-flex xs12 sm4>
                  <v-img
                    contain
                    class="px-2"
                    :src="images.house"
                    :lazy-src="images.house"
                  ></v-img>
                </v-flex>

                <v-flex xs12 sm4>
                  <v-img
                    contain
                    class="px-2"
                    :src="images.life"
                    :lazy-src="images.life"
                  ></v-img>
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex xs12 sm4 pb-0>
                  <p class="primary--text graphik-bold font-20 text-xs-center mb-0"> {{ $ml.get('about_insurance_car_title') }}</p>
                </v-flex>

                <v-flex xs12 sm4 pb-0>
                  <p class="primary--text graphik-bold font-20 text-xs-center mb-0"> {{ $ml.get('about_insurance_house_title') }}</p>
                </v-flex>

                <v-flex xs12 sm4 pb-0>
                  <p class="primary--text graphik-bold font-20 text-xs-center mb-0"> {{ $ml.get('about_insurance_life_title') }}</p>
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex xs12 sm4>
                  <p class="dark-grey--text graphik-light font-20 text-xs-center mb-0"> {{ $ml.get('about_insurance_car_description') }}</p>
                </v-flex>

                <v-flex xs12 sm4>
                  <p class="dark-grey--text graphik-light font-20 text-xs-center mb-0"> {{ $ml.get('about_insurance_house_description') }}</p>
                </v-flex>

                <v-flex xs12 sm4>
                  <p class="dark-grey--text graphik-light font-20 text-xs-center mb-0"> {{ $ml.get('about_insurance_life_description') }}</p>
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex xs12 sm4 text-xs-center>
                  <v-btn 
                    color="primary"
                    depressed dark
                    :loading="g_isLoading"
                    class="normalcase graphik-bold-italic border-radius-5"
                    @click="$goTo('/auto', 'goto_car')"
                  >{{ $ml.get('general_know_more') }}</v-btn>
                </v-flex>

                <v-flex xs12 sm4 text-xs-center>
                  <v-btn 
                    color="primary"
                    depressed dark
                    :loading="g_isLoading"
                    class="normalcase graphik-bold-italic border-radius-5"
                    @click="$goTo('/hogar', 'goto_house')"
                  >{{ $ml.get('general_know_more') }}</v-btn>
                </v-flex>

                <v-flex xs12 sm4 text-xs-center>
                  <v-btn 
                    color="primary"
                    depressed dark
                    :loading="g_isLoading"
                    class="normalcase graphik-bold-italic border-radius-5"
                    @click="$goTo('/vida', 'goto_life')"
                  >{{ $ml.get('general_know_more') }}</v-btn>
                </v-flex>
              </v-layout>
            </template>

            <template v-else>
              <v-layout row wrap text-xs-center>
                <v-flex xs12 py-5>
                  <v-img
                    contain
                    class="px-2 mb-3"
                    :src="images.car"
                    :lazy-src="images.car"
                  ></v-img>

                  <p class="primary--text graphik-bold font-20 text-xs-center mb-2"> {{ $ml.get('about_insurance_car_title') }}</p>
                  <p class="dark-grey--text graphik-light font-20 text-xs-center"> {{ $ml.get('about_insurance_car_description') }}</p>

                  <v-btn 
                    color="primary"
                    depressed dark
                    :loading="g_isLoading"
                    class="normalcase graphik-bold-italic border-radius-5"
                    @click="$goTo('/auto', 'goto_car')"
                  >{{ $ml.get('general_know_more') }}</v-btn>
                </v-flex>

                <v-flex xs12 py-5>
                  <v-img
                    contain
                    class="px-2 mb-3"
                    :src="images.house"
                    :lazy-src="images.house"
                  ></v-img>

                  <p class="primary--text graphik-bold font-20 text-xs-center mb-2"> {{ $ml.get('about_insurance_house_title') }}</p>
                  <p class="dark-grey--text graphik-light font-20 text-xs-center"> {{ $ml.get('about_insurance_house_description') }}</p>

                  <v-btn 
                    color="primary"
                    depressed dark
                    :loading="g_isLoading"
                    class="normalcase graphik-bold-italic border-radius-5"
                    @click="$goTo('/hogar', 'goto_house')"
                  >{{ $ml.get('general_know_more') }}</v-btn>
                </v-flex>

                <v-flex xs12 py-5>
                  <v-img
                    contain
                    class="px-2 mb-3"
                    :src="images.life"
                    :lazy-src="images.life"
                  ></v-img>

                  <p class="primary--text graphik-bold font-20 text-xs-center mb-2"> {{ $ml.get('about_insurance_life_title') }}</p>
                  <p class="dark-grey--text graphik-light font-20 text-xs-center"> {{ $ml.get('about_insurance_life_description') }}</p>

                  <v-btn 
                    color="primary"
                    depressed dark
                    :loading="g_isLoading"
                    class="normalcase graphik-bold-italic border-radius-5"
                    @click="$goTo('/vida', 'goto_life')"
                  >{{ $ml.get('general_know_more') }}</v-btn>
                </v-flex>
              </v-layout>
            </template>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'ContentAboutProducts',
  data () {
    return {
      images: {
        car: require('@/assets/img/images/about_car.png'),
        house: require('@/assets/img/images/about_house.png'),
        life: require('@/assets/img/images/about_life.png')
      }
    }
  }
}
</script>

<style scoped>
</style>
